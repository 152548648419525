import Paginate from "vuejs-paginate";

let commPaginateMixin = {
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      paginateConfig: {
        pageCurrent: 1, // 기본 현재 페이지
        pageCount: 0, // 총 페이지 수
        ulClass: "list_paginate", // 페이징 ul 요소의 클래스 세팅
        prevText: '<span class="ico_account">이전</span>', // 이전 버튼
        nextText: '<span class="ico_account">다음</span>', // 다음 버튼
        pageRange: 10, // 보여지는 페이지의 수
        countPerPage: 10, // 보여지는 페이지의 수
        marginPage: 1, // 추가로 보여질 페이지의 수
        pageList: [
          { code: 10, name: "10" },
          { code: 30, name: "30" },
          { code: 50, name: "50" },
          { code: 100, name: "100" },
        ],
      },
    };
  },
  mounted() {
    // this.setPaginateArrow()
  },
  methods: {
    /**
     * 이전, 다음 버튼의 마크업 생성 및 클릭 이벤트 세팅
     */
    setPaginateArrow() {
      let pArrow = document.createElement(`span`);
      pArrow.classList.add(`ico_account`);
      pArrow.innerText = `이전`;

      let nArrow = document.createElement(`span`);
      nArrow.classList.add(`ico_account`);
      nArrow.innerText = `다음`;

      document.querySelector(".list_paginate").firstElementChild.firstChild.appendChild(pArrow);
      document.querySelector(".list_paginate").lastElementChild.firstChild.appendChild(nArrow);
    },
  },
};

export default commPaginateMixin;
