<template>
  <div class="area_list">
    <div class="head_tbl">
      <strong class="screen_out">결과 목록</strong>
      <!--      <div class="wrap_btn">-->
      <!--        <div class="pos_right">-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!-- // head_tbl -->
    <div class="tbl_comm tbl_list">
      <table>
        <caption class="ir_caption">
          결과 목록 표
        </caption>
        <colgroup>
          <col style="width: 96px" />
          <!-- 구매요청일 -->
          <col style="width: 84px" />
          <!-- 결재 완료일 -->
          <col style="width: 116px" />
          <!-- 구매요청 번호 -->
          <col />
          <!-- 구매요청명 -->
          <col style="width: 114px" />
          <!-- 구매구분 -->
          <col style="width: 130px" />
          <!-- 구매 카테고리 -->
          <col style="width: 12%" />
          <!-- 신청자 -->
          <col style="width: 12%" />
          <!-- 결재자 -->
          <col style="width: 132px" />
          <!-- 정산서등록 -->
        </colgroup>
        <thead>
          <tr>
            <th>구매요청일</th>
            <th>결재 완료일</th>
            <th>구매요청 번호</th>
            <th>구매요청명</th>
            <th>구매 구분</th>
            <th>구매 카테고리</th>
            <th>신청자</th>
            <th>결재자<br />(구매/발주담당자)</th>
            <th>정산서등록</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="dataList.length === 0">
            <td colspan="9">조회된 정산서가 없습니다.</td>
          </tr>
          <tr v-for="(item, index) in dataList" :key="index">
            <td>{{ item.drftDate }}</td>
            <td>{{ item.docStatusDate }}</td>
            <td>{{ item.requestCid }}</td>
            <td class="td_ellip align_left" :title="item.title">
              <router-link
                v-if="
                  purchaseDraftDetailPath(item.pdDocStatus, item.pdAprvlStatus, item.requestCid) !=
                  'disabled'
                "
                :to="purchaseDraftDetailPath(item.pdDocStatus, item.pdAprvlStatus, item.requestCid)"
                class="link_detail"
              >
                {{ item.title }}
              </router-link>
              <template v-else>
                {{ item.title }}
              </template>
            </td>
            <td>{{ assetType(item.assetType) }}</td>
            <td
              class="td_ellip"
              :title="
                handleNum(item.goodsCnt)
                  ? item.handleTitle + handleNum(item.goodsCnt)
                  : item.handleTitle
              "
            >
              {{ item.handleTitle }}{{ handleNum(item.goodsCnt) }}
            </td>
            <td class="td_ellip" :title="item.drftAccountId + '(' + item.drftEmpName + ')'">
              {{ item.drftAccountId }}({{ item.drftEmpName }})
            </td>
            <td class="td_ellip" :title="item.aprvlAccountId + '(' + item.aprvlEmpName + ')'">
              {{ item.aprvlAccountId }}({{ item.aprvlEmpName }})
            </td>
            <td>
              <template v-if="item.settlementCid">
                <router-link
                  v-if="
                    statementDetailPath(item.dsDocStatus, item.dsAprvlStatus, item.settlementCid) !=
                    'disabled'
                  "
                  :to="
                    statementDetailPath(item.dsDocStatus, item.dsAprvlStatus, item.settlementCid)
                  "
                  class="link_detail"
                >
                  {{ item.settlementCid }}
                </router-link>
                <template v-else>
                  {{ item.settlementCid }}
                </template>
              </template>
              <button v-else class="btn_small btn_fourthly" @click.prevent="onclickReg(item)">
                등록
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paging_comm" :class="paginateWrapClass">
      <paginate
        v-model="paginateConfig.pageCurrent"
        :click-handler="setPaging"
        :container-class="paginateConfig.ulClass"
        :prev-text="paginateConfig.prevText"
        :next-text="paginateConfig.nextText"
        :page-range="paginateConfig.pageRange"
        :margin-pages="paginateConfig.marginPage"
        :page-count="paginateConfig.pageCount || 0"
      />
    </div>
  </div>
</template>

<script>
import { default as CommPaginateMixin } from "@/_approval/mixins/common/commPaginate.js";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import { getPurchaseDraftDetailPath, getStatementDetailPath } from "@/utils/DetailPathUtils";
import commDocs from "@/_approval/mixins/common/commDocs";

export default {
  name: "StatementManagementList",
  mixins: [CommPaginateMixin, CommLayerMixin, CommToastMixin, commDocs],
  props: {
    // 전체 페이지 수
    totalPage: {
      type: Number,
      default: 1,
    },
    dataList: Array,
  },
  data() {
    return {
      currentPage: 1,
      prevDisable: false,
      nextDisable: false,
      totalDocCount: 0,
    };
  },
  computed: {
    paginateWrapClass() {
      return this.dataList && (!this.dataList.length || this.dataList.length === 0)
        ? "paging_none"
        : "";
    },
  },
  watch: {
    dataList() {
      this.totalDocCount = this.dataList ? this.dataList.length : 0;
      this.paginateConfig.pageCount = this.totalPage;
      if (this.dataList && this.dataList.length > 0) {
        this.prevDisable = this.currentPage > 1;
        this.nextDisable = this.currentPage < this.totalPage;
        this.pagination = [];
        for (let i = -1; i <= 1; i++) {
          let isActive = false;
          let page = this.paginateConfig.pageCurrent + i;
          if (page === this.currentPage) {
            isActive = true;
          }
          if (page > 0 && page <= this.totalPage) {
            this.pagination.push({
              page: page,
              isActive: isActive,
            });
          }
        }
      }
    },
  },
  mounted() {
    this.setPaging(this.currentPage);
    // console.log('routerPath', this.$routerPath.PURCHASE_REQUEST_DRAFT_COMPLETE_DETAIL);
  },
  methods: {
    handleNum(handleNum) {
      if (handleNum <= 1) return;
      return ` 외 ${handleNum - 1}건`;
    },
    assetType(assetType) {
      switch (assetType) {
        case "H":
          return "일반구매";
        case "I":
          return "자산구매";
        case "G":
          return "총무자산";
      }
      return "";
    },
    selectDocList() {
      this.$emit("getDocList", this.paginateConfig);
    },
    setPaging(page) {
      this.paginateConfig.pageCurrent = Number(page); // 선택한 페이지 번호 값 세팅 (필수)
      this.selectDocList();
    },
    purchaseDraftDetailPath(pdDocStatus, pdAprvlStatus, requestCid) {
      // return this.$routerPath.PURCHASE_REQUEST_VIEW_DETAIL+'?docNo='+ requestCid;
      if (getPurchaseDraftDetailPath(pdDocStatus, pdAprvlStatus) == "disabled") {
        return "disabled";
      }
      return getPurchaseDraftDetailPath(pdDocStatus, pdAprvlStatus) + "?docNo=" + requestCid;
    },
    statementDetailPath(dsDocStatus, dsAprvlStatus, settlementCid) {
      if (getStatementDetailPath(dsDocStatus, dsAprvlStatus) == "disabled") {
        return "disabled";
      }
      return getStatementDetailPath(dsDocStatus, dsAprvlStatus) + "?docNo=" + settlementCid;
    },
    onclickReg(item) {
      // const path = this.$routerPath.STATEMENT_WRITE+'?requestCid='+item.requestCid;
      // this.$router.push(path);
      this.$router.push({
        name: "StatementWrite",
        query: { requestCid: item.requestCid },
      });
    },
  },
};
</script>
